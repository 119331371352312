.oneclick-button-with_icon {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  color: #fcfcfc;
  letter-spacing: 1.07px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  opacity: 1;
}

.oneclick-button-with_icon > span {
  opacity: 0.8;
}

@media only screen and (min-width: 768px) and (min-height: 500px) {
  .oneclick-button-with_icon {
    font-size: 12px;
  }
}
