html {
  font-size: 100%;
  line-height: 100%;
}

body {
  background-color: #121320;
  /* background-color: #FFB829; */
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Barlow', sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: 'hidden';
}

#root {
  background-color: #121320;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Barlow';
  font-weight: 900;
  letter-spacing: 1px;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type='file'] {
  width: 100%;
}

.error-message {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  color: #b93d1b;
  letter-spacing: 0.33px;
  text-align: left;
  line-height: 1.2;
  padding-left: 10px;
  max-width: 500px;
}
.error-message > div {
  padding-bottom: 10px;
}
.error-message > div::before {
  content: '• ';
  position: absolute;
  left: 0;
}
