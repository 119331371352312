body {
  font-family: Barlow, sans-serif;
  color: #756767;
  font-size: 14px;
  line-height: 18px;
}

.bg {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-image: url('../images/rockies-bg.jpg');
  background-position: 0px 50%;
  background-size: cover;
}

.bg.executive-dashboard {
  padding: 0px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/bg_pattern.jpg');
  background-size: cover;
  background-repeat: repeat-x;
  font-family: Barlow, sans-serif;
}

.signin-panel {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #ffb829;
  box-shadow: 0 0 50px 25px rgba(0, 0, 0, 0.3);
  font-family: Barlow, sans-serif;
  color: #756767;
}

.logo {
  position: absolute;
  top: 60px;
  right: 60px;
  width: 80px;
}

.text.txt_signup {
  margin-top: 60px;
  font-family: Barlow, sans-serif;
  color: #756767;
  font-size: 24px;
  font-weight: 500;
}

.text.txt_copyright {
  position: absolute;
  left: 60px;
  bottom: 30px;
  font-size: 9px;
  line-height: 10px;
}

.text.txt_signup--error {
  margin-top: 60px;
  font-family: Barlow, sans-serif;
  color: #756767;
  font-size: 24px;
  font-weight: 500;
}

.box-sign-up {
  width: 100%;
  max-width: 750px;
  margin-left: 60px;
}

.field_input {
  height: 38px;
  max-width: 600px;
  margin-bottom: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #fff;
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
}

.field_input:focus {
  font-weight: 400;
}

.field_input::-webkit-input-placeholder {
  color: #fff;
  font-size: 18px;
}

.field_input:-ms-input-placeholder {
  color: #fff;
  font-size: 18px;
}

.field_input::placeholder {
  color: #fff;
  font-size: 18px;
}

.form-block {
  margin-top: 60px;
}

.button {
  margin-top: 15px;
}

.button.btn_regular {
  height: 50px;
  padding-right: 60px;
  padding-left: 60px;
  border-radius: 25px;
  font-size: 17px;
  font-weight: 400;
}

@media only screen and (max-width: 394px) {
  .button.btn_regular {
    padding: 0;
  }
}

.button.btn_calendar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button.arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.arrow:hover {
  background-color: rgba(93, 123, 157, 0.08);
}

.button.closex {
  position: absolute;
  top: 0px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-right: -18px;
  margin-left: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header {
  padding: 15px;
  text-align: center;
}

.image {
  max-width: 30px;
}

.date-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  border-bottom: 1px solid #b7b3b4;
}

.div-block {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.selector.date-and-time {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #e0dfdf;
  font-size: 10px;
  line-height: 10px;
  text-decoration: none;
}

.date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/icon_arrow.svg');
  background-position: 0px 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  font-family: Barlow, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.reports-generated-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  margin-bottom: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ffb829;
  background-image: url('../images/icon_divider.svg');
  background-position: 50% 50%;
  background-size: auto 100px;
  background-repeat: no-repeat;
}

.active-users-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  margin-bottom: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent;
}

.dbrd-number-of-accounts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ffb829;
}

.dbrd-accounts-breakdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  margin-bottom: 15px;
}

.dbrd-average-useage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  border-radius: 5px;
  background-color: #756767;
}

.block-blue-1x {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom-left-radius: 5px;
  background-color: #64abff;
}

.block-blue-2x {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #4a90e2;
}

.block-blue-3x {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom-right-radius: 5px;
  background-color: #5d7b9d;
}

.dashboard {
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

.monthly-active-user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-image: -webkit-linear-gradient(270deg, #fff, #d8e2eb);
  background-image: linear-gradient(180deg, #fff, #d8e2eb);
}

.weekly-active-users {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top-right-radius: 5px;
  background-color: #64abff;
}

.time-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 41px 30px 30px 90px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.reports-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-6 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-7 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.daily-active-users {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-bottom-right-radius: 5px;
  background-color: #4a90e2;
}

.metric-label {
  margin-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  color: #756767;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.metric-label.txt_blue--light {
  color: rgba(208, 229, 255, 0.5);
}

.metric-label.number-of-accounts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 0px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.metric-label.usage-time {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 0px;
  padding-right: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffb829;
  text-align: right;
}

.number_large {
  color: #756767;
  font-size: 60px;
  line-height: 45px;
  font-weight: 800;
  text-align: center;
}

.number_large.txt_blue--large {
  color: #64abff;
  font-size: 90px;
  line-height: 90px;
}

.number_large.txt_blue--dark {
  color: #497cd1;
  font-size: 45px;
}

.number_large.txt_blue--light {
  color: #c2daf5;
  font-size: 45px;
}

.number_large.number-accounts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 90px;
}

.number_large.txt_blue--darkx2 {
  color: #3262b3;
  font-size: 45px;
}

.number_large.txt_blue--dimmed {
  color: #a2b5cb;
  font-size: 45px;
}

.number_large.usage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 45px;
}

.metric-selector-link_active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #756767;
  font-weight: 800;
  letter-spacing: 2px;
  text-decoration: none;
}

.metric-selector-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: hsla(0, 6%, 43%, 0.5);
  font-weight: 800;
  letter-spacing: 2px;
  text-decoration: none;
}

.weekly-active-users-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
}

.overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.calendar-container {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 400px;
  min-height: 100px;
  padding: 30px 15px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 5px;
  background-color: #fff;
}

.calendar-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 525px;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: transparent;
}

.calendar-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 1px;
  margin-right: 1px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #5d7b9d;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}

.month {
  width: 30px;
  height: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-3 {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #5d7b9d;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 5px;
}

.calendar-date_inactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 1px;
  margin-right: 1px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #bac2c7;
  font-size: 16px;
  font-weight: 500;
}

.calendar-date_selected {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 1px;
  margin-right: 1px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #64abff;
  box-shadow: 0 0 11px 3px rgba(76, 111, 187, 0.45);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.calendar-date_highlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 1px;
  margin-right: 1px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #a2c3ea;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.calendar-date_active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 1px;
  margin-right: 1px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #a2c3ea;
  font-size: 16px;
  font-weight: 500;
}

.success-message {
  padding: 0px;
  background-color: transparent;
  color: #756767;
  font-size: 18px;
  font-weight: 300;
}

.text-block-2 {
  text-align: left;
}

.error-message {
  margin-top: 31px;
}

.paragraph {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.image-4 {
  width: 100%;
  max-width: 450px;
}

@media (max-width: 991px) {
  .bg.executive-dashboard {
    background-size: cover;
  }
  .bg.splash {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .signin-panel {
    width: 80vw;
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 10px;
  }
  .logo {
    top: 45px;
    right: 45px;
    width: 100px;
    max-width: 50px;
  }
  .box-sign-up {
    max-width: 100%;
    margin-left: 0px;
    padding: 45px;
  }
  .paragraph {
    margin-top: 30px;
    font-size: 14px;
    line-height: 22px;
  }
  .image-4 {
    max-width: 350px;
  }
}

@media (max-width: 812px) and (max-height: 375px) {
  .signin-panel {
    width: 100%;
    min-height: 800px;
    padding: 30px;
    border-radius: 10px;
  }
  .bg.splash {
    display: block;
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .bg.executive-dashboard {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-position: 0px 50%;
    background-size: cover;
    background-repeat: repeat-x;
  }
  .bg.splash {
    display: block;
    padding: 15px;
  }
  .signin-panel {
    width: 100%;
    min-height: 800px;
    padding: 30px;
    border-radius: 10px;
  }
  .logo {
    position: static;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .logo_type {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .text.txt_signup {
    margin-top: 30px;
  }
  .text.txt_copyright {
    position: static;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }
  .box-sign-up {
    max-width: 100%;
    margin-left: 0px;
    padding: 0px;
    text-align: center;
  }
  .form-block {
    text-align: center;
  }
  .button.btn_regular {
    width: 100%;
  }
  .form {
    text-align: center;
  }
  .time-selector {
    padding-top: 33px;
    padding-left: 40px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .metric-label {
    letter-spacing: 1px;
  }
  .metric-label.txt_blue--dark {
    letter-spacing: 1px;
  }
  .metric-label.txt_blue--light {
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .metric-label.number-of-accounts {
    padding-top: 7px;
  }
  .metric-label.usage-time {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .number_large {
    font-size: 45px;
  }
  .number_large.txt_blue--large {
    font-size: 60px;
    line-height: 60px;
  }
  .number_large.number-accounts {
    font-size: 70px;
    line-height: 70px;
  }
  .metric-selector-link_active {
    height: 40px;
  }
  .metric-selector-link {
    height: 40px;
  }
  .overlay {
    display: none;
  }
  .text-block-2 {
    text-align: center;
  }
  .paragraph {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid hsla(0, 6%, 43%, 0.2);
    border-bottom: 1px solid hsla(0, 6%, 43%, 0.2);
  }
}

@media (max-width: 479px) {
  body {
    height: 100%;
  }
  .bg.executive-dashboard {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-size: cover;
    background-repeat: repeat-x;
  }
  .bg.splash {
    width: 100%;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .signin-panel {
    width: 100%;
    max-width: 400px;
    box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.3);
  }
  .logo {
    margin-top: 30px;
  }
  .text.txt_signup {
    margin-top: 30px;
    font-size: 18px;
    text-align: left;
  }
  .text.txt_signup--error {
    margin-top: 30px;
    color: #b93d1b;
  }
  .form-block {
    margin-top: 30px;
  }
  .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 90px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .date-selector {
    height: 40px;
    margin-bottom: 1vh;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .selector.date-and-time {
    height: 40px;
  }
  .date {
    letter-spacing: 3px;
  }
  .reports-generated-container {
    height: 200px;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 2px;
    background-color: #ffb829;
    background-image: none;
  }
  .active-users-container {
    height: 175px;
    background-color: #fff;
  }
  .dbrd-number-of-accounts {
    height: 90px;
    border-radius: 2px;
    background-color: #ffb829;
  }
  .dbrd-accounts-breakdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 110px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 2px;
    background-color: #ffb829;
  }
  .dbrd-average-useage {
    height: 60px;
    margin-bottom: 1vh;
    border-radius: 2px;
    background-color: #756767;
  }
  .block-blue-1x {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-bottom-left-radius: 2px;
    background-color: #64abff;
  }
  .block-blue-2x {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #4a90e2;
  }
  .block-blue-3x {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-bottom-right-radius: 2px;
    background-color: #5d7b9d;
  }
  .monthly-active-user {
    border-top-left-radius: 2px;
  }
  .weekly-active-users {
    border-top-right-radius: 2px;
  }
  .time-selector {
    width: 100%;
    margin-top: 22px;
    padding: 0px 14px 0px 35px;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .reports-number {
    width: 100%;
  }
  .metric-label {
    margin-top: 5px;
    font-size: 10px;
    line-height: 12px;
  }
  .metric-label.txt_blue--dark {
    margin-top: 5px;
  }
  .metric-label.txt_blue--light {
    font-size: 10px;
  }
  .metric-label.usage-time {
    padding-right: 10px;
    font-size: 10px;
  }
  .number_large {
    font-size: 30px;
    line-height: 30px;
  }
  .number_large.txt_blue--large {
    font-size: 45px;
    line-height: 45px;
  }
  .number_large.txt_blue--dark {
    font-size: 20px;
    line-height: 20px;
  }
  .number_large.txt_blue--light {
    font-size: 20px;
    line-height: 20px;
  }
  .number_large.number-accounts {
    font-size: 30px;
    line-height: 30px;
  }
  .number_large.txt_blue--darkx2 {
    font-size: 20px;
    line-height: 20px;
  }
  .number_large.txt_blue--dimmed {
    font-size: 20px;
    line-height: 20px;
  }
  .number_large.usage {
    font-size: 25px;
    line-height: 25px;
  }
  .metric-selector-link_active {
    height: 25px;
    font-size: 12px;
  }
  .metric-selector-link_active.align-right {
    padding-right: 15px;
    font-size: 10px;
    text-align: right;
  }
  .metric-selector-link {
    height: 25px;
    font-size: 10px;
  }
  .metric-selector-link.align-right {
    padding-right: 15px;
    text-align: right;
  }
  .calendar-container {
    max-width: 320px;
  }
  .calendar-date {
    width: 100%;
    height: 40px;
    max-width: 40px;
  }
  .calendar-date_inactive {
    width: 100%;
    height: 40px;
    max-width: 40px;
  }
  .calendar-date_selected {
    width: 100%;
    height: 40px;
    max-width: 40px;
  }
  .calendar-date_highlight {
    width: 100%;
    height: 40px;
    max-width: 40px;
  }
  .calendar-date_active {
    width: 100%;
    height: 40px;
    max-width: 40px;
  }
  .text-block-2 {
    line-height: 22px;
  }
  .paragraph {
    margin-top: 30px;
    margin-bottom: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #756767;
    font-size: 12px;
    line-height: 22px;
    text-align: left;
  }
}
