/* colors */
.white {
  color: white;
}
.orange {
  color: #ffb829;
}
.brown {
  color: #756767;
}
.light-gray {
  color: #d8d8d8;
}
.dark-gray {
  color: #7e7e7e;
}

.bg-highlight_blue {
  background-color: #64abff;
}
.bg-brown {
  background-color: #756767;
}
.bg-orange {
  background-color: #ffb829;
}
.bg-white {
  background-color: #fff;
}
.bg-light-gray {
  background-color: #f9f9f9;
}

/* styling */
.uppercase {
  text-transform: uppercase;
}
.nowrap {
  white-space: nowrap;
  word-break: keep-all;
}
.bold {
  font-weight: bold;
}
.em {
  font-style: italic;
}
.qtr-spaced {
  letter-spacing: 0.25em;
}

/* layout */
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.centered {
  text-align: center;
}
.text-left {
  text-align: left;
}
.all-centered {
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* sizing - conversions based on 16px base font */
.lineheight1 {
  line-height: 1px;
}
.tiny {
  font-size: 0.625em; /* 10px */
  letter-spacing: 0.051875rem;
}

.small {
  font-size: 0.75em; /* 12px */
  line-height: 1.5em; /* 18px */
}
.regular {
  font-size: 0.875em; /* 14px */
  line-height: 1.428571428em; /* 20px */
}
.medium {
  font-size: 1.125em; /* 18px */
  line-height: 1.111111111em; /* 20px */
}
.large {
  font-size: 1.5em; /* 24px */
  line-height: 1.25em; /* 30px */
}
.xlarge {
  font-size: 2.25em;
} /* 36px */

@media only screen and (min-width: 768px) and (min-height: 500px) {
  .hero {
    background-position: 0 0;
  }

  .hero,
  .heroGradient {
    height: auto;
    min-height: 95vh;
  }

  .tiny {
    font-size: 0.875em; /* 14px */
    letter-spacing: 0.08357142857em; /* 1.17px */
  }
  .small {
    font-size: 1.125em; /* 18px */
    line-height: 1.5em; /* 27px */
  }
  .regular {
    font-size: 1.25em; /* 20px */
    line-height: 1.428571428em; /* 20px */
  }
  .medium {
    font-size: 1.875em; /* 30px */
    line-height: 1.2em; /* 36px */
  }
  .large {
    font-size: 1.875em; /* 30px */
    line-height: auto;
  }

  .xlarge {
    font-size: 2.25em;
  } /* 36px */

  .qtr-spaced {
    letter-spacing: 0.15em;
  }

  .card .large {
    font-size: 1.5em; /* 24px */
    font-weight: bold;
  }

  .italic-large-screen {
    font-style: italic;
  }
}
