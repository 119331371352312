.newProject-button {
  width: 100%;
  background: rgba(100, 171, 255, 0.2);
  border-radius: 5px;
  padding: 9px;
  display: flex;
  align-items: center;
  font-weight: 800;
  line-height: 13px;
}
