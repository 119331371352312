.oneclick-large-user_menu-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* padding: 16.5px 0; */
}

.oneclick-large-user_menu-button > div:nth-child(1) {
  width: 36px;
}

.oneclick-large-user_menu-button > div:nth-child(2) {
  flex: 1;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
