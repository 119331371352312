.oneclick-button-flashy {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.oneclick-button-flashy.oneclick-button-flash-disabled {
  cursor: default;
}
/* .oneclick-button-flashy > *  {
  position: relative;
  cursor: pointer;
  user-select: none;
  z-index: 2;
}  */

.oneclick-button-flashy:after {
  content: '';
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* opacity: 1; */
  /* z-index: 1; */
}

.oneclick-button-flashy-enlarge:after {
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  /* background-color: red; */
}

.oneclick-dashboard-large-button {
  height: 90px;
}

@media only screen and (min-width: 768px) {
  .oneclick-dashboard-large-button {
    /* height: 139px; */
  }
}
