#modal-root .oneclick-modal-fade {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  z-index: 1000000;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.oneclick-modal-background {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 15px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.oneclick-modal-close {
  align-self: flex-end;
}

@media only screen and (min-width: 768px) and (min-height: 500px) {
  #modal-root .oneclick-modal-fade {
    justify-content: center;
    align-items: center;
    bottom: 0;
    background-color: rgba(51, 51, 64, 0.65);
    z-index: 1000000;
  }

  .oneclick-modal-background {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    padding: 50px;
  }

  .oneclick-modal-close {
    position: absolute;
    top: 23px;
    right: 23px;
  }
}
